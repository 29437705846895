<template>
  <div class="private-equity-apply">
    <div class="wrapper">
      <div class="title font-md">
        <b>펀드 참여 신청</b>
      </div>
      <div class="desc font-sm">
        <div>사모펀딩은 한도 제한 없이 투자 참여가 가능합니다.</div>
        <p>프로젝트당 49인 이하의 투자자만 참여가 가능하며, 승인된 투자자만 투자 정보를 조회하실 수 있습니다.</p>
        <p>참가 신청 후 오마이컴퍼니 담당자가 검토하여 결과를 안내해드립니다. 투자 가능한 금액을 입력해주세요.</p>
      </div>
      <div class="form">
        <div class="form-group">
          <Number :id="`${component.name}Amount`" placeholder="희망 투자 금액 (단위: 원)" :value.sync="state.amount"/>
        </div>
      </div>
    </div>
    <div class="action">
      <button :id="`${component.name}Submit`" class="btn btn-point btn-block font-sm" @click="submit()">신청하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "modalPrivateEquityApply";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Number},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xs"});
      state.investSeq = modalParams.investSeq;
    });

    const state = reactive({
      investSeq: "",
      amount: "",
    });

    const modalParams = store.getters.modalParams(component);
    const projectSeq = modalParams.projectSeq;

    const submit = () => {
      if (!state.amount) {
        document.getElementById(`${component.name}Amount`).focus();
        return store.commit("setSwingMessage", "희망 투자 금액을 입력해주세요.");
      }

      const args = {
        amount: state.amount,
      };

      http.post(`/api/invest/projects/${projectSeq}/private-equity-application`, args).then(() => {
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "사모펀딩 신청을 완료했습니다.");
          }
        });
      }).catch(httpError((err) => {
        err?.response?.status === 401 && err?.response?.data?.code === "INVESTOR_AUTH_REQUIRED" && router.push({path: "/mypage/investor"});
      }));
    };

    return {component, state, submit};
  }
});
</script>

<style lang="scss" scoped>
.private-equity-apply {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      color: #666;
    }

    .form {
      .form-group {
        margin-bottom: 0;

        input[type=text], input[type=tel], input[type=password], select {
          height: $formHeight;
          width: 100%;
        }

        > .wrapper {
          display: table;
          table-layout: fixed;
          margin: 0 $px-5;
          width: 100%;
        }
      }
    }
  }

  .action {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>